import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Flowers from './components/Flowers';
import FlowerDetail from './components/FlowerDetail';
import Summary from './components/Summary';
import OrderConfirmation from './components/OrderConfirmation'; // Import OrderConfirmation

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/flowers" element={<Flowers />} />
        <Route path="/flowers/:flowerName" element={<FlowerDetail />} />
        <Route path="/flowers/summary" element={<Summary />} /> {/* Update path for Summary */}
        <Route path="/flowers/order-confirmation" element={<OrderConfirmation />} /> {/* Update path for OrderConfirmation */}
      </Routes>
    </Router>
  );
}

export default App;