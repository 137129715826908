import React from 'react';
import { Link } from 'react-router-dom';
import floralData from './json/floral_data.json';
import './css/Flowers.css'; 
import fallbackImage from './images/hydrangea.png'; // Import the fallback image

function Flowers() {
  const getImageSrc = (imageName) => {
    return imageName ? `/images/${imageName}` : fallbackImage;
  };

  return (
    <div className="container">
      <h1 className="my-4">Flower Data</h1>
      <div className="row">
        {floralData.map((flower, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 mb-4 d-flex align-items-stretch">
            <div className="card w-100">
              <img 
                src={getImageSrc(flower.image)} 
                className="card-img-top" 
                alt={flower["Flower Name"]} 
              />
              <div className="card-body">
                <h5 className="card-title">{flower["Flower Name"]}</h5>
                <Link to={`/flowers/${flower["Flower Name"].toLowerCase().replace(/ /g, '-')}`} className="btn btn-primary">
                  View More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Flowers;