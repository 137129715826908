import React from 'react';
import logo from '../logo.svg';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          COD <code>BLR</code> Online
        </p>
      </header>
    </div>
  );
}

export default Home;