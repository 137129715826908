import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

function Summary() {
  const location = useLocation();
  const navigate = useNavigate();
  const { action, flower, selectedColors: initialSelectedColors, quantity, deliverAsBouquet, giftingPackaging, bulkPurchase, subscriptionFrequency } = location.state;
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [selectedColors, setSelectedColors] = useState(initialSelectedColors);
  const [errors, setErrors] = useState({});

  const colors = [
    { name: "Blue", hex: "#0000FF" },
    { name: "Green", hex: "#008000" },
    { name: "Red", hex: "#FF0000" },
    { name: "Orange", hex: "#FFA500" },
    { name: "Maroon", hex: "#800000" },
    { name: "White", hex: "#FFFFFF" },
    { name: "Lavender", hex: "#E6E6FA" },
    { name: "Dark Blue", hex: "#00008B" },
    { name: "Sweet Pink", hex: "#FFB6C1" },
    { name: "Yellow", hex: "#FFFF00" },
    { name: "Purple", hex: "#800080" },
    { name: "Baby pink", hex: "#F4C2C2" },
    { name: "Peach", hex: "#FFDAB9" },
    { name: "Rani Pink", hex: "#E0115F" }
  ];

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!phoneNumber) newErrors.phoneNumber = 'Phone number is required';
    if (!selectedColors.length) newErrors.selectedColors = 'At least one color must be selected';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleConfirmOrder = () => {
    if (!validate()) return;

    const orderId = uuidv4();

    const orderData = {
      orderId,
      action,
      flower,
      selectedColors,
      quantity,
      deliverAsBouquet,
      giftingPackaging,
      bulkPurchase,
      subscriptionFrequency,
      name,
      phoneNumber,
      address
    };

    Cookies.set('orderData', JSON.stringify(orderData), { expires: 7 });

    navigate('/flowers/order-confirmation', { state: orderData });
  };

  const toggleColorSelection = (colorName) => {
    setSelectedColors(prevSelectedColors =>
      prevSelectedColors.includes(colorName)
        ? prevSelectedColors.filter(color => color !== colorName)
        : [...prevSelectedColors, colorName]
    );
  };

  const totalCost = (flower["Selling Price"] * quantity) / flower["Min Quantity"];

  return (
    <div className="container">
      <h1 className="my-4">Summary</h1>
      <p><strong>Action:</strong> {action}</p>
      <p><strong>Flower Name:</strong> {flower["Flower Name"]}</p>
      <p><strong>Category:</strong> {flower.Category}</p>
      <p><strong>Min Quantity:</strong> {flower["Min Quantity"]}</p>
      <p><strong>Selling Price:</strong> ₹ {flower["Selling Price"]}</p>
      <p><strong>Total Cost:</strong> ₹ {totalCost.toFixed(2)}</p>
      <p><strong>Selected Colors:</strong> {selectedColors.join(', ')}</p>
      <p><strong>Quantity:</strong> {quantity}</p>
      <p><strong>Deliver as a Bouquet:</strong> {deliverAsBouquet ? 'Yes' : 'No'}</p>
      <p><strong>Gifting Packaging:</strong> {giftingPackaging ? 'Yes' : 'No'}</p>
      <p><strong>Bulk Purchase:</strong> {bulkPurchase ? 'Yes' : 'No'}</p>
      {subscriptionFrequency && <p><strong>Subscription Frequency:</strong> {subscriptionFrequency}</p>}
      <div className="mb-4">
        <label><strong>Name:</strong></label>
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && <div className="text-danger">{errors.name}</div>}
      </div>
      <div className="mb-4">
        <label><strong>Phone Number:</strong></label>
        <input
          type="tel"
          className="form-control"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
      </div>
      <div className="mb-4">
        <label><strong>Address:</strong></label>
        <textarea
          className="form-control"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      {errors.selectedColors && (
        <div className="mb-4">
          <div className="text-danger">{errors.selectedColors}</div>
          <div>
            {colors.filter(color => flower[color.name] === "TRUE").map((color, index) => (
              <button
                key={index}
                className="btn m-1"
                style={{
                  backgroundColor: selectedColors.includes(color.name) ? color.hex : 'transparent',
                  borderColor: color.name === "White" ? 'black' : color.hex,
                  color: selectedColors.includes(color.name) ? color.hex : (color.name === "White" ? 'black' : color.hex)
                }}
                onClick={() => toggleColorSelection(color.name)}
              >
                {color.name}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="mb-4">
        <button className="btn btn-success m-1" onClick={handleConfirmOrder}>Confirm and Place Order</button>
        <Link to="/flowers" className="btn btn-secondary m-1">Back to Flowers</Link>
      </div>
      <p>Our telecaller will contact you to confirm your phone number.</p>
    </div>
  );
}

export default Summary;