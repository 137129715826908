import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import floralData from './json/floral_data.json';
import fallbackImage from './images/hydrangea.png'; // Import the fallback image

function FlowerDetail() {
  const { flowerName } = useParams();
  const navigate = useNavigate();
  const flower = floralData.find(f => f["Flower Name"].toLowerCase().replace(/ /g, '-') === flowerName);
  const [selectedColors, setSelectedColors] = useState([]);
  const [quantity, setQuantity] = useState(parseInt(flower["Min Quantity"]));
  const [deliverAsBouquet, setDeliverAsBouquet] = useState(false);
  const [giftingPackaging, setGiftingPackaging] = useState(false);
  const [bulkPurchase, setBulkPurchase] = useState(false);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState('weekly');

  const getImageSrc = (imageName) => {
    return imageName ? `/images/${imageName}` : fallbackImage;
  };

  if (!flower) {
    return <h2>Flower not found</h2>;
  }

  const colors = [
    { name: "Blue", hex: "#0000FF" },
    { name: "Green", hex: "#008000" },
    { name: "Red", hex: "#FF0000" },
    { name: "Orange", hex: "#FFA500" },
    { name: "Maroon", hex: "#800000" },
    { name: "White", hex: "#FFFFFF" },
    { name: "Lavender", hex: "#E6E6FA" },
    { name: "Dark Blue", hex: "#00008B" },
    { name: "Sweet Pink", hex: "#FFB6C1" },
    { name: "Yellow", hex: "#FFFF00" },
    { name: "Purple", hex: "#800080" },
    { name: "Baby pink", hex: "#F4C2C2" },
    { name: "Peach", hex: "#FFDAB9" },
    { name: "Rani Pink", hex: "#E0115F" }
  ];

  const toggleColorSelection = (colorName) => {
    setSelectedColors(prevSelectedColors =>
      prevSelectedColors.includes(colorName)
        ? prevSelectedColors.filter(color => color !== colorName)
        : [...prevSelectedColors, colorName]
    );
  };

  const incrementQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + parseInt(flower["Min Quantity"]));
  };

  const decrementQuantity = () => {
    setQuantity(prevQuantity => Math.max(parseInt(flower["Min Quantity"]), prevQuantity - parseInt(flower["Min Quantity"])));
  };

  const handleAction = (action) => {
    navigate('/flowers/summary', {
      state: {
        action,
        flower,
        selectedColors,
        quantity,
        deliverAsBouquet,
        giftingPackaging,
        bulkPurchase,
        subscriptionFrequency
      }
    });
  };

  return (
    <div className="container">
      <h1 className="my-4">{flower["Flower Name"]}</h1>
      <img src={getImageSrc(flower.image)} className="img-fluid mb-4" alt={flower["Flower Name"]} />
      <p><strong>Category:</strong> {flower.Category}</p>
      <p><strong>Min Quantity:</strong> {flower["Min Quantity"]}</p>
      <p><strong>Selling Price:</strong> ₹ {flower["Selling Price"]}</p>
      <p><strong>Colors Available:</strong></p>
      <div className="mb-4">
        {colors.filter(color => flower[color.name] === "TRUE").map((color, index) => (
          <button
            key={index}
            className="btn m-1"
            style={{
              backgroundColor: selectedColors.includes(color.name) ? color.hex : 'transparent',
              borderColor: color.name === "White" ? 'black' : color.hex,
              color: selectedColors.includes(color.name) ? color.hex : (color.name === "White" ? 'black' : color.hex)
            }}
            onClick={() => toggleColorSelection(color.name)}
          >
            {color.name}
          </button>
        ))}
      </div>
      <div className="mb-4">
        <p><strong>Selected Colors:</strong></p>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {selectedColors.length === 0 && <p>No colors selected</p>}
          {selectedColors.length === 1 && (
            <div style={{ backgroundColor: colors.find(color => color.name === selectedColors[0]).hex, width: '100px', height: '100px', border: '1px solid black' }}></div>
          )}
          {selectedColors.length > 1 && (
            <div style={{ display: 'flex', width: '100px', height: '100px', border: '1px solid black' }}>
              {selectedColors.map((colorName, index) => {
                const color = colors.find(color => color.name === colorName);
                return (
                  <div key={index} style={{ backgroundColor: color.hex, flex: 1 }}></div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label><strong>Quantity:</strong></label>
        <div className="input-group">
          <button className="btn btn-outline-secondary" type="button" onClick={decrementQuantity}>-</button>
          <input
            type="number"
            className="form-control text-center"
            value={quantity}
            readOnly
          />
          <button className="btn btn-outline-secondary" type="button" onClick={incrementQuantity}>+</button>
        </div>
      </div>
      <div className="mb-4">
        <label><strong>Deliver as a Bouquet:</strong></label>
        <input
          type="checkbox"
          className="form-check-input ms-2"
          checked={deliverAsBouquet}
          onChange={(e) => setDeliverAsBouquet(e.target.checked)}
        />
      </div>
      <div className="mb-4">
        <label><strong>Gifting Packaging:</strong></label>
        <div className="form-check form-check-inline ms-2">
          <input
            type="radio"
            className="form-check-input"
            name="giftingPackaging"
            value="yes"
            checked={giftingPackaging === true}
            onChange={() => setGiftingPackaging(true)}
          /> Yes
        </div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            name="giftingPackaging"
            value="no"
            checked={giftingPackaging === false}
            onChange={() => setGiftingPackaging(false)}
          /> No
        </div>
      </div>
      <div className="mb-4">
        <label><strong>Bulk Purchase:</strong></label>
        <input
          type="checkbox"
          className="form-check-input ms-2"
          checked={bulkPurchase}
          onChange={(e) => setBulkPurchase(e.target.checked)}
        />
      </div>
      <div className="mb-4">
        <label><strong>Subscription Frequency:</strong></label>
        <select
          className="form-control"
          value={subscriptionFrequency}
          onChange={(e) => setSubscriptionFrequency(e.target.value)}
        >
          <option value="weekly">Weekly</option>
          <option value="daily">Daily</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="">One Time Purchase</option>
        </select>
      </div>
      <div className="mb-4">
        {subscriptionFrequency === "" ? (
          <button className="btn btn-primary m-1" onClick={() => handleAction('Buy One Time')}>Buy One Time</button>
        ) : (
          <button className="btn btn-primary m-1" onClick={() => handleAction('Subscribe')}>Subscribe</button>
        )}
        <button className="btn btn-primary m-1" onClick={() => handleAction('Send Enquiry')}>Send Enquiry</button>
        <button className="btn btn-primary m-1" onClick={() => handleAction('Request Call Back')}>Request Call Back</button>
      </div>
      <Link to="/flowers" className="btn btn-secondary">Back to Flowers</Link>
    </div>
  );
}

export default FlowerDetail;