import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

function OrderConfirmation() {
  const location = useLocation();
  const { orderId, action, flower, selectedColors, quantity, deliverAsBouquet, giftingPackaging, bulkPurchase, subscriptionFrequency, name, phoneNumber, address } = location.state;
  const [urgentMessage, setUrgentMessage] = useState('');

  const handleUrgentOrder = () => {
    setUrgentMessage('Please call our customer service to place your order urgently.');
  };

  const colors = [
    { name: "Blue", hex: "#0000FF" },
    { name: "Green", hex: "#008000" },
    { name: "Red", hex: "#FF0000" },
    { name: "Orange", hex: "#FFA500" },
    { name: "Maroon", hex: "#800000" },
    { name: "White", hex: "#FFFFFF" },
    { name: "Lavender", hex: "#E6E6FA" },
    { name: "Dark Blue", hex: "#00008B" },
    { name: "Sweet Pink", hex: "#FFB6C1" },
    { name: "Yellow", hex: "#FFFF00" },
    { name: "Purple", hex: "#800080" },
    { name: "Baby pink", hex: "#F4C2C2" },
    { name: "Peach", hex: "#FFDAB9" },
    { name: "Rani Pink", hex: "#E0115F" }
  ];

  const totalCost = (flower["Selling Price"] * quantity) / flower["Min Quantity"];

  return (
    <div className="container">
      <h1 className="my-4">Order Confirmation</h1>
      <p>Order ID: {orderId}</p>
      <p>Order confirmed! Our telecaller will contact you to confirm your purchase.</p>
      <p><strong>Action:</strong> {action}</p>
      <p><strong>Flower Name:</strong> {flower["Flower Name"]}</p>
      <p><strong>Category:</strong> {flower.Category}</p>
      <p><strong>Min Quantity:</strong> {flower["Min Quantity"]} / ₹ {flower["Selling Price"]}</p>
      <p><strong>Selected Quantity:</strong> {quantity}</p>
      <p><strong>Total Cost:</strong> ₹ {totalCost.toFixed(2)}</p>
      <p><strong>Selected Colors:</strong> {selectedColors.join(', ')}</p>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedColors.length === 1 && (
          <div style={{ backgroundColor: colors.find(color => color.name === selectedColors[0]).hex, width: '100px', height: '100px', border: '1px solid black' }}></div>
        )}
        {selectedColors.length > 1 && (
          <div style={{ display: 'flex', width: '100px', height: '100px', border: '1px solid black' }}>
            {selectedColors.map((colorName, index) => {
              const color = colors.find(color => color.name === colorName);
              return (
                <div key={index} style={{ backgroundColor: color.hex, flex: 1 }}></div>
              );
            })}
          </div>
        )}
      </div>
      <p><strong>Deliver as a Bouquet:</strong> {deliverAsBouquet ? 'Yes' : 'No'}</p>
      <p><strong>Gifting Packaging:</strong> {giftingPackaging ? 'Yes' : 'No'}</p>
      <p><strong>Bulk Purchase:</strong> {bulkPurchase ? 'Yes' : 'No'}</p>
      {subscriptionFrequency && <p><strong>Subscription Frequency:</strong> {subscriptionFrequency}</p>}
      <p><strong>Name:</strong> {name}</p>
      <p><strong>Phone Number:</strong> {phoneNumber}</p>
      <p><strong>Address:</strong> {address}</p>
      <a href="tel:+918848618977" className="btn btn-warning m-1">Click to Call</a>
      <Link to="/flowers" className="btn btn-secondary m-1">Back to Flowers</Link>
      {urgentMessage && <div className="alert alert-warning mt-4">{urgentMessage}</div>}
    </div>
  );
}

export default OrderConfirmation;